/* If you need to add @import statements, do so up here */
@import "fonts.css";

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .m-section {
    display: block;
    margin: theme("spacing.16") 0;
  }
  @media screen(sm) {
    margin: theme("spacing.28") 0;
  }
  
  .m-container {
    display: block;
    padding: 0 theme("spacing.8");
  }
  @media screen(sm) {
    padding: 0 theme("spacing.28");
  }
}

/* Prose */

.Prose h2 {
  font-size: theme("fontSize.4xl");
  line-height: theme("lineHeight.10");
  font-weight: theme("fontWeight.medium");
  max-width: 25ch;
  margin-bottom: theme("spacing.16");
}
@media screen(sm) {
  .Prose h2 {
    font-size: theme("fontSize.5xl");
    line-height: theme("lineHeight.none");
  }
}
@media screen(lg) {
  .Prose h2 {
    font-size: theme("fontSize.6xl");
    line-height: theme("lineHeight.none");
    letter-spacing: theme("letterSpacing.tight");
  }
}
.Prose * + h2 {
  margin-top: theme("spacing.16");
}

.Prose h3 {
  font-size: theme("fontSize.3xl");
  line-height: theme("lineHeight.9");
  font-weight: theme("fontWeight.medium");
  margin-bottom: theme("spacing.3");
}
.Prose * + h3 {
  margin-top: theme("spacing.9");
}

.Prose h4 {
  font-size: theme("fontSize.2xl");
  line-height: theme("lineHeight.8");
  font-weight: theme("fontWeight.medium");
  margin-bottom: theme("spacing.3");
}
.Prose * + h4 {
  margin-top: theme("spacing.9");
}

.Prose h5 {
  font-weight: theme("fontWeight.medium");
  margin-bottom: theme("spacing[1.5]");
}
.Prose * + h5 {
  margin-top: theme("spacing.6");
}

.Prose hr {
  margin: theme("spacing.6") 0;
}

.Prose p {
  max-width: 640px;
}
.Prose p + p {
  margin-top: theme("spacing.3");
}

.Prose strong {
  font-weight: theme("fontWeight.medium")
}

.Prose ul {
	list-style-type: disc;
	list-style-position: inside;
}

.Prose ol {
	list-style-type: decimal;
	list-style-position: inside;
}
